import React from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import '../i18next';
import { ResetCSS } from 'common/src/assets/css/style';
import SEO from '../components/seo';
import Navbar from '../containers/CryptoModern/Navbar';
import GlobalStyle, {
  CryptoWrapper,
  ContentWrapper
} from '../containers/CryptoModern/cryptoModern.style';
import Footer from '../containers/CryptoModern/Footer';
import { theme } from 'common/src/theme/cryptoModern';

export default function(props) {
  const { title, children } = props;
  return (
    <ThemeProvider theme={theme}>
      <SEO title={title} />
      <ResetCSS />
      <GlobalStyle />
      <CryptoWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-active">
          <Navbar />
        </Sticky>
        <ContentWrapper>{children}</ContentWrapper>
        <Footer />
      </CryptoWrapper>
    </ThemeProvider>
  );
}
