import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './dictionaries/en';
import th from './dictionaries/th';

const resources = {
  en: en,
  th: th
};

let lang = 'th';
if (typeof window !== 'undefined') {
  lang = window.localStorage.getItem('lang') || 'th';

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop)
  });

  const langWhiteList = ['th', 'en'];
  const langParam = params.lang ? params.lang.toLowerCase() : '';
  if (langParam && langWhiteList.includes(langParam)) {
    lang = langParam;
  }
}

i18n.use(initReactI18next).init({
  lng: lang,
  fallbackLng: lang,
  resources: resources
});

export default i18n;
