import React, { useState, useRef } from 'react';
import Fade from 'react-reveal/Fade';
import { Link } from 'gatsby';
import ScrollSpyMenu from 'common/src/components/ScrollSpyMenu';
import Scrollspy from 'react-scrollspy';
import { Icon } from 'react-icons-kit';
import { menu } from 'react-icons-kit/feather/menu';
import { x } from 'react-icons-kit/feather/x';
import Logo from 'common/src/components/UIElements/Logo';
import Button from 'common/src/components/Button';
import Container from 'common/src/components/UI/Container';
import useOnClickOutside from 'common/src/hooks/useOnClickOutside';
import NavbarWrapper, { MenuArea, MobileMenu } from './navbar.style';
import LogoImage from 'common/src/assets/image/cryptoModern/logo-white.png';
import LogoImageAlt from 'common/src/assets/image/cryptoModern/logo.png';
import config from '../../../config';
import { useTranslation } from 'react-i18next';

const Navbar = () => {
  const [state, setState] = useState({
    mobileMenu: false
  });

  const searchRef = useRef(null);
  useOnClickOutside(searchRef, () =>
    setState({ ...state, searchToggle: false })
  );

  const toggleHandler = type => {
    if (type === 'search') {
      setState({
        ...state,
        mobileMenu: false
      });
    }

    if (type === 'menu') {
      setState({
        ...state,
        mobileMenu: !state.mobileMenu
      });
    }
  };

  const { t } = useTranslation();
  const navMenu = [
    {
      id: 1,
      label: t('nav.home'),
      path: '/',
      offset: '84'
    },
    {
      id: 2,
      label: t('nav.about'),
      path: '/about',
      offset: '81'
    },
    {
      id: 4,
      label: t('nav.howTo'),
      path: '/howto',
      offset: '81'
    },
    {
      id: 5,
      label: t('nav.faqs'),
      path: '/faqs',
      offset: '81'
    }
  ];
  const scrollItems = [];

  navMenu.forEach(item => {
    scrollItems.push(item.path.slice(1));
  });

  // const handleRemoveMenu = () => {
  //   setState({
  //     ...state,
  //     mobileMenu: false,
  //   });
  // };

  return (
    <NavbarWrapper className="navbar">
      <Container>
        <Logo
          href="/"
          logoSrc={LogoImage}
          title="One Option Logo"
          className="main-logo"
        />
        <Logo
          href="/"
          logoSrc={LogoImageAlt}
          title="One Option Logo"
          className="logo-alt"
        />
        {/* end of logo */}

        <MenuArea className={state.searchToggle ? 'active' : ''}>
          <ScrollSpyMenu className="menu" menuItems={navMenu} offset={-84} />
          <a
            href={`${config.appUrl}/login`}
            title={t('nav.signIn')}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button className="trail" title={t('nav.signIn')} />
          </a>

          <Button
            className="menubar"
            icon={
              state.mobileMenu ? (
                <Icon className="bar" icon={x} />
              ) : (
                <Fade>
                  <Icon className="close" icon={menu} />
                </Fade>
              )
            }
            color="#0F2137"
            variant="textButton"
            onClick={() => toggleHandler('menu')}
          />
        </MenuArea>
      </Container>

      {/* start mobile menu */}
      <MobileMenu className={`mobile-menu ${state.mobileMenu ? 'active' : ''}`}>
        <Container>
          <Scrollspy
            className="menu"
            items={scrollItems}
            offset={-84}
            currentClassName="active"
          >
            <li key={`menu_key_1`}>
              <Link to="/">{t('nav.home')}</Link>
            </li>
            <li key={`menu_key_2`}>
              <Link to="/about">{t('nav.about')}</Link>
            </li>
            <li key={`menu_key_3`}>
              <Link to="/howto">{t('nav.howTo')}</Link>
            </li>
            <li key={`menu_key_4`}>
              <Link to="/faqs">{t('nav.faqs')}</Link>
            </li>
          </Scrollspy>
          <a
            href={`${config.appUrl}/login`}
            title={t('nav.signIn')}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button title={t('nav.signIn')} />
          </a>
        </Container>
      </MobileMenu>
      {/* end of mobile menu */}
    </NavbarWrapper>
  );
};

export default Navbar;
