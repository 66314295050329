export default {
  translation: {
    home: {
      title: 'Your easy investment, We can give you more',
      subTopic1: 'Up to 60% more bonus',
      subTopic2: 'for every deposit.',
      topic:
        'One option access, Easy to working, Can use everywhere and every time.',
      topicDesc:
        'For investments that everyone has never experienced before. It’s the best investment right now. Everyone can start trading. In Thailand, We give the most payout rates and We are the only have new functions for anyone who dares not invest.',
      bannerBtn1: 'Signin',
      bannerBtn2: 'Singup',
      cta: {
        topic: 'Why invest with MM World Option?',
        desc: 'One Option makes investment easy, and we can give you more.',
        cta1: {
          title: 'Low investment $1',
          desc: 'Invest easily without worrying about budget.'
        },
        cta2: {
          title: 'More profit',
          desc: 'More profit compared to others and more worthwhile for you.'
        },
        cta3: {
          title: 'No deposit fee',
          desc: 'Minimum of deposit at $10 and ready to invest right away.'
        },
        cta4: {
          title: '$10,000 trial account',
          desc: 'Trial account for you to try by your own. And it’s free!'
        }
      },
      section1: {
        title: 'One Option open right now in Thailand.',
        desc:
          'Anyone who looking for investment we recommend One Option as your choice. We guaranteed this system is efficient for investment with minimal risk.'
      },
      section2: {
        title: 'New members of One Option receive an exclusive bonus.',
        title2: 'Promotion Day, One Option',
        title3:
          'Every deposit Unlimited investment, Will be receive the special bonus 100% from 00:00 to 23:59, Do not miss it, 1 month only once (the company will announce monthly promotions)',
        list1: 'Deposit $50 Bonus 10%',
        list2: 'Deposit $100 Bonus 50%',
        list3: 'Deposit $5000 Bonus 100%'
      },
      section3: {
        title: 'One Option Mobile Application',
        title2: 'Easy to trade anywhere and anytime, coming soon',
        list1: 'Download MM World Option Mobile Application',
        list2: 'Discover trial account',
        list3: 'Start making profit',
        desc: 'Mobile Application ready for Download today'
      },
      section4: {
        userAmout1: 'Active Users',
        userAmout2: 'Online Users'
      }
    },
    about: {
      title: 'About Us',
      topic: 'MM ONLINE MARKETS, LLC',
      contentOne:
        'We have developed the most efficient trading system, focusing on the development and service for trader’s convenience. We deposit trader’s fund separately in foreign bank for the highest security. We provide trading techniques and gala dinner seminars for the trader regularly without any charge.',
      contentTwo:
        'We always have great promotions for the traders. Every month you can collect scores and exchange them for rewards. We have a fast deposit and withdrawal system. We have a support team to help traders 24 hours a day. We developed the system so that everyone could access and easily make profit. We also have a trial account for the traders to practice trading.'
    },
    howTo: {
      title: 'How to order and how to use MM World Option',
      topic: 'How to order and how to use MM World Option',
      topicDesc:
        'How to use MM World Option system. You can make a profit in just a few steps. We have designed the system to be easily used by users of all genders and ages.',
      tab1: {
        label: '1. Select asset',
        topic: '1. Select asset',
        desc:
          'You can select the asset that you want to invest from the left side. The system will show the graph for your selected asset. Each asset might have different returns. You can open up to 6 asset screens.'
      },
      tab2: {
        label: '2. Specify the amount and time',
        topic: '2. Specify money and time for your investment',
        desc:
          'After the investment asset selection, the candlestick chart is analyzed to estimate results from selected asset. On the right side, select the time for investment (default value is 1 minute) and specify investment amount. Make sure to check the asset returns before every investment as the returns will be constantly changing according to the market price.'
      },
      tab3: {
        label: '3. Select an investment, long or short',
        topic: '3. Select whether your investment will be long or short',
        desc:
          'Select the investment, short or long, to invest in the selected asset’s result whether it would be increased or decreased from the current amount. Time and amount will be as specified in number two.'
      },
      tab4: {
        label: '4. Get profit',
        topic: '4. Check the result and get profit immediately',
        desc:
          'After the investment order has been released, you can check the order on progress (not yet due) on the right side. You will see order on progress at “ORDER” tab. You could also check the completed Order history at the menu “Trading History”.'
      }
    },
    faqs: {
      title: 'Faqs',
      topic: 'Have a question ?',
      topicDesc:
        'Take a look at the frequently asked questions that might help you. We have prepared the answer for you.',
      topic2: {
        title: 'Recommendation commission conditions:',
        list1:
          '1. Recommendation commission could be received not more than $500 per month.',
        list2:
          '2. The recommender must deposit more than 10% of the recommendation commission received with at least 72 hours of account balance holding period.',
        list3:
          '3. If the recommendation commission is more than $500 per month, the excess amount will not be considered as income and will be deducted from the system.',
        list4:
          '4. If the recommendation commission is not withdrawn within 45 days, the amount will be deducted by the system.',
        desc:
          'If referrer withdrawing commission rewards, The accounts must have at least 2 active transactions within 3 days before commission withdrawal.'
      },
      q1: {
        title: '1. How much is the minimum deposit amount?',
        description: 'Minimum deposit amount is $10'
      },
      q2: {
        title:
          '2. If there is no activity in the account, will there be any commission fee?',
        description:
          'No commission fees. However, if there is no activity for more than 120 days, the account will be automatically closed.'
      },
      q3: {
        title: '3. Is there any fee for withdrawal?',
        description: 'No withdrawal fees.'
      },
      q4: {
        title: '4. How could I deposit the money?',
        description:
          'You can deposit the money through credit card, PayPal, or bank transfer to the specified account.'
      },
      q5: {
        title: '5. How could I withdraw the money?',
        description:
          'You can request withdrawal at Withdrawal menu. The receipt account is the account that you have verified your identity.'
      },
      q6: {
        title: '6. How long is the withdrawal period?',
        description:
          '5 Minutes – 3 days (Approximately, depending on investor’s queue)'
      },
      q7: {
        title: '7. How much is the minimum withdrawal amount?',
        description: 'Minimum withdrawal amount is $15'
      },
      q8: {
        title: '8. How much is the minimum and maximum trading amount?',
        description:
          'You can trade from a minimum of $1 to maximum of $5,000 per 1 order.'
      },
      q9: {
        title: '9. How to complete the document verification?',
        description:
          'Upload the document at your profile page and wait for the verification from our team (document verification is done only once).'
      },
      q10: {
        title: '10.	How long does it take to verify the document?',
        description:
          'Approval process will be not more than 3 days, but the trader can start the deposit and trading immediately after the registration (document verification is done only once).'
      },
      q11: {
        title: '11. How do I know that the documents are verified?',
        description: 'You could check the status at the menu “My information”.'
      },
      q12: {
        title: '12.	What documents are required for identity verification?',
        description:
          'National ID card or password (such as telephone bills, credit card bills, water-electricity bills, etc.), first page of the bankbook.'
      },
      q13: {
        title: '13.	What are the bonus conditions?',
        description:
          'The bonus received could be used for investment purposes. If there is any loss, his/her own funds will be deducted before the bonus.'
      },
      q14: {
        title: '14.	Could I withdraw the bonus?',
        description:
          'The bonus could not be withdrawn but could be used for trading purposes. The profit from the trading could be withdrawn (for every withdrawal, bonus will be deducted from the account).'
      },
      q15: {
        title: '15.	How could we utilize the bonus?',
        description:
          'The bonus could be traded for profit, increase order quantity, and exchanged to scores for the rewards.'
      },
      q16: {
        title: '16.	What is the score?',
        description:
          'Score could be used to exchange for monthly reward. The more score you have, the more reward you could exchange.'
      },
      q17: {
        title: '17.	How to get a score?',
        description:
          'The score could be received by depositing into the account according to the criteria. For every new order, you would get 1 score. The score could be gained through participating in activities.'
      },
      q18: {
        title: '18.	How to use bonus?',
        description:
          'When the actual balance in the account is $0.00, the bonus could be used to trade.'
      },
      q19: {
        title: '19.	What is the maximum amount for each withdrawal?',
        description:
          'For each time, the maximum amount for withdrawal is 50,000 Dollar.'
      }
    },
    terms: {
      title: 'Terms And Conditions',
      topic:
        'This contract is made between ONE OPTION and the member registered hereinafter referred to as “the client”.',
      list: {
        item1:
          '1. The company has the right to amend the transactions and content solely. The company also has the right to add information and documents or remove or alter any content without prior notice.',
        item2:
          '2. The company is able terminate the contract and proposal made in various conditions If the client breaches the agreement or provide false information to the company. The company is able terminate the contract without any conditions. Client’s transactions become subject to this contract.',
        item3:
          '3. Clients must review the condition and agreements by accepting the terms of the appendix mentioned.',
        item4:
          '4. Clients must affirm and admit that they are of the age of maturity in accordance with the jurisdiction and are not clients in OPTION trading countries, which may be considered illegal.',
        item5:
          '5. The client confirms that all information given that the client uses to register is all true and complete data. The application is self registration',
        item6:
          '6. Under is agreement, Clients have the right to report usage problems or any matters that need to be resolved by oneself.',
        item7:
          '7. Clients will conduct transactions and trades that are not themselves at their responsibility and on their own expense.',
        item8:
          '8. Clients will not borrow funds from anyone other than their own funds in the transaction.',
        item9:
          '9. Clients enter into transactions of your own accord, not by being influenced by others.',
        item10:
          '10. Clients will not cause the company damaged or tarnished. Clients will not cooperate with other parties or third parties with the aim of destroying the company. Clients will not use special tools and will not make use of mistakes or system problems that they have discovered in revenue retrieval and will not disclose information with third parties',
        item11:
          '11. Clients will not conduct transactions with dishonest way to the company. Clients will not use insiders or confidential information to gain own benefits when conducting transactions (trading) with the company or causing damage to the company.',
        item12:
          '12. Clients will comply with international laws and regulations that focus on the prevention of illegal trading, corruption, financial fraud, and legal prosecution.',
        item13:
          '13. Clients will not use trading systems and company websites or the company in illegal financial transactions and other illegal activities',
        item14:
          '14. The client’s money transferred to that company must be legitimate. The client’s account will not be topped up by any other person and clients will not transfer their own fund to the bank or electronic wallet of any person other than themselves',
        item15:
          '15. All actions made by clients will not violate the legislative system any laws or regulations applicable in the jurisdiction where the client resides.',
        item16:
          '16. In doing any transactions, clients agree to use their own devices and agrees not to use other people’s devices for the transactions. Clients will not use the program to conceal the real IP address or the actual location.',
        item17:
          '17. Terms of use of bonuses and prizes shall be as specified by the company',
        item18:
          '18. The client must not have a relationship with the country or state in which the company does not operate in a business or in a country that is illegal. In this regard, the Company holds the rights to the interpretation and use of words at the discretion of the company.',
        item19:
          '19. The laws in this contract set general principles for the transaction. The company is able to add and amend transaction and contract terms and can limit the number of client’s transactions within the time specified by the company at the sole discretion of the company',
        item20:
          '20. The company may hire third party provider to operate or comply with this contract. However, the company is not responsible for services provided by the third party.',
        item21:
          '21. Trading and closing trade is the wish of client who earns or loses money which the company has no responsibility for this matter. The company does not lend money to the client. The company and client who is trading up and trading down. Option is the price that is equal to the assets or the price set by the client. Payment will take place when the price changes to the desired direction and is higher than the target at the system timeout.',
        item22:
          '22. Bonus is a virtual currency the company offers to the client’s account. The client follows the conditions set by the company by spending the client’s currency for trading before spending the bonus to process the transactions. The client has no right to transfer bonus to other person or withdraw money. The company has the right to deduct bonus from the client’s account and set the minimum price for trading, as well as other conditions if the client follows the instruction or queries about bonus. Some bonus types can be withdrawn in the amount of money. Transfer bonus to actual money. About the efficiency of other performance. Bonus offering might be published to the company. Apart from bonus, the company offers promotions and other options to attract and retain the clients.',
        item23:
          '23. Income from profit earning is according to the amount stipulated in the conditions or other expenses are considered clients’ expenses, not the company',
        item24:
          '24. The percentage of replacement is established by the company in accordance with the conditions and fluctuations that the company has referred to the option asset.',
        item25:
          '25. Any dispute with the contract or any matters relating to the company are significant regardless the condition and beyond other disputes.',
        item26:
          '26. Key conditions of trading determining the payment. Direction of price, higher or lower price.',
        item27:
          '27. The client’s account or the special account applied to the company.  Money transferred by the client for option trading will be deducted at the time of closing trade. The client is allowed to have one account with the company only. In case of any infringement, the company has the right to deny providing service to the client or terminate the contract without informing the reason and payment.',
        item28:
          '28. Amount of money from the transactions will be deducted from the client’s account immediately after trading. Earnings from trading will be added to the client’s account that is applied with the company after closing trade.',
        item29:
          '29. The password that the client has set for security purpose, the company will consider the application, the registration on the website, all transactions, orders, and trading sent by clients themselves',
        item30:
          '30. Close trading status will occur if that transaction is not possible at the current trading price.',
        item31:
          '31. The company is able to communicate with clients via email, telephone, text message or mail in resolving problems or giving information to clients. The contact based on the information provided by the client during registration will be used by the company.',
        item32:
          '32. Within an hour after sending an email, the client is considered to have received the information. When the conversation over the phone has ended, the client is considered to have received the data. After sending all types of messages or mails online or text messages, we will be considered as clients immediately acknowledged. After five days of mailing, the company will assume that the client has received it. ',
        item33:
          '33. Client agrees in the event that the client has inappropriate behavior during conversations with company staffs, the company reserves the exclusive right to cancel or terminate this contract.',
        item34:
          '34. The company will determine the frequency of contacting clients, information transmission or various advertisements at the sole discretion of the company.',
        item35:
          '35. After the registration is completed, clients are allowed to conduct trading transactions. If the client wishes to transfer money from the account that the client has registered with the company to other client’s bank account or electronic wallet, clients must submit a document verifying the identity of the client designated by the company in order to be able to withdraw funds. Clients will be asked to submit documents only the first fund withdrawal.',
        item36:
          '36. The client must immediately notify the company when there is a change regarding his identification documents and contact information within ten days after the change. To verify the client’s source of funds can be made at any time when registration with the company is completed. The company has the right to request any additional documents. (National ID card issued by government, Document confirming residence, and financial statement document)',
        item37:
          '37. The company has the right to terminate/suspense the client transactions if the client authentication is incorrect. If the client does not provide the required document, the company has the right to hold the trade until the authentication process completes.',
        item38:
          '38. The client agrees that he has no right to transfer or sell the account or data or password to other party.',
        item39:
          '39. Transaction or trading order from the client’s account is considered the complete process by the client.',
        item40:
          '40. The company has no responsibility for any possible damage. The client shall study information and look for knowledge before trading. The company has no responsibility for the damage from the robbery, loss, password disclosure to other party, as well as the use of device by other party.',
        item41:
          '41. The client has the right to change the password by themselves.',
        item42:
          '42. The client shall complain to or claim to the company when there is the mistake caused by the company. The company discretion is absolute for the compensation.',
        item43:
          '43. Petition or claim shall not have emotional assessment in the dispute, threat statement, contumely, or the word of mouth to other. The company has the right to require more document and information from the client.',
        item44:
          '44. The company has the right to deny the claim and complaint, or the infringement that is not the liability of the company. The company considers the complaint or claim within 30 days after acknowledgement.',
        item45:
          '45. In case of dispute, the company reserves the right to stop partial or the whole operation of the client’s account.',
        item46:
          '46. Complaint or laws are controlled by the law of the country where the company is registered. The company shall agree to adhere to the law and court decree from the law and court of the country where the company is registered only. ',
        item47:
          '47. In force majeure where the company has sufficient grounds can be accused as the force majeure (including but not limited to rebellion, terrorism, war, natural disaster, accident, fire, flood, storm, power outage, equipment or software malfunction, instability, system and software error, and etc.).',
        item48:
          '48. In case of force majeure, the company has the right to perform the following actions.',
        item48_1: 'a. Inform in non-written form at any time.',
        item48_2:
          'b. Cancel any trade or all trades that possibly has the direct or indirect impact.',
        item48_3:
          'c. Suspense or amend any part of regulation in the contract as long as the regulation is not effective due to the force majeure.',
        item49:
          '49. The company has no liability for the inappropriate achievement in case of the situation where the force is incapable.',
        item50:
          '50. The damage caused by the client from the cooperation with other clients or other party to damage the company by using the dishonest and unfair method or technique to perform trading with the company, as well as the use of bonus or rewards from the company in any situation. Participator refers to anyone who is not the client. The company has the tight to block the client transactions and trading if the client violates the conditions of this contract. The company has the right to:',
        item50_1:
          'a. amend the amount of financial obligation of the client (client balance)',
        item50_2:
          'b. suspend the service and block the trading. The client must show the reasonable evidence or information for the correction within 30 days. The company shall withdraw all money from the trading account if the client violates this contract.',
        item51:
          '51. In the event that the company terminates the contract due to violation of the rules or violation of the clients himself ,the client will not have the right to open a new account.',
        item52:
          '52. The company shall not liable to clients for damages, loss opportunity, or damages of any kind caused from market fluctuations',
        item53:
          '53. The company shall not liable in the event that the data discrepancy between the display of trading data of the client and on the company’s server.',
        item54:
          '54. The company has no liability for the client damage caused from the hacker attack, the failure of computer network or the operating system, or other conditions of company operations.',
        item55:
          '55. The client shall be informed that the transaction of this contract has the risk of the non-achievement of expected return and the partial loss or the loss of all money transferred to the client account.',
        item56:
          '56. In case of the non-achievement of the obligation with or without purpose, the company has no liability for the loss of expenses and capital, any damage of the client caused from the incorrect data sending to the client.',
        item57:
          '57. This contract is effective when the client’s registration is completed and with unlimited duration of membership.',
        item58:
          '58. In the event that the contract is terminated, the money must be refunded to the client within forty-five days if he is entitled to a refund.',
        item59:
          '59. Amendments or additions to the contract can be made solely by the company. The effective date will be designed by the company.',
        item60:
          '60. The company may transfer rights and obligations in whole or in part to third parties without prior notice to the clients in which the company is not responsible for any operation processed by third party.',
        item61:
          '61. The client has no right to assign or transfer the right or obligation without the consent of the company unless it does not declare on the contract which the company shall process the fair and acceptable practice.',
        item62:
          '62. The company has the right to use the statement in this document in other language. Any contrast between the statement on the contract, the statement on the original contract will be effective.',
        item63:
          '63. If there is dispute or problem in all cases,The members must be agree to the law in the country where the company is registered only.',
        item64:
          '64 When the members agree it means that the member voluntarily accepts the laws and conditions of the company.'
      }
    },
    nav: {
      home: 'Home',
      about: 'About',
      howTo: 'How To',
      faqs: 'Faqs',
      signIn: 'Signin'
    },
    footer: {
      phoneOne: 'Phone : 1-800-256-8218',
      phoneTwo: 'Phone : 1-661-335-8168',
      email: 'Email : oneoptionteam@gmail.com',
      nav: {
        title: 'Navigation',
        home: 'Home',
        about: 'About',
        howTo: 'How To',
        faqs: 'Faqs'
      },
      account: {
        title: 'Account',
        signIn: 'Signin',
        signUp: 'Signup'
      },
      terms: {
        title: 'Policy And Languages',
        termsAndCon: 'Terms And Conditions',
        termsAndConTH: 'ข้อกำหนดและเงื่อนไข',
        termsAndConEN: 'Terms And Conditions'
      },
      lang: {
        title: 'Languages',
        th: 'ไทย',
        en: 'English'
      },
      contact: {
        title: 'Contact',
        line: 'Line Official'
      }
    }
  }
};
