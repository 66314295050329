import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Logo from 'common/src/components/UIElements/Logo';
import Container from 'common/src/components/UI/Container';
import FooterWrapper, { List, ListItem } from './footer.style';

import LogoImage from 'common/src/assets/image/cryptoModern/logo-white.png';
import { useTranslation } from 'react-i18next';
import LineQR from '../../../images/line-oa-qr.png';

import config from '../../../config';

const Footer = ({
  row,
  col,
  colOne,
  colTwo,
  titleStyle,
  logoStyle,
  textStyle
}) => {
  const { t, i18n } = useTranslation();
  const year = new Date().getFullYear();
  const copyRightText = `Copyright ${year} @One Option.`;
  const handleLang = lang => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('lang', lang);
    }
    i18n.changeLanguage(lang || 'th');
  };
  return (
    <FooterWrapper>
      <Container className="footer_container">
        <Box className="row" {...row}>
          <Box {...colOne}>
            <Logo
              href="/"
              logoSrc={LogoImage}
              title="One Option Logo"
              logoStyle={logoStyle}
            />
            <Text content="MM ONLINE MARKETS, LLC" {...textStyle} />
            {/* <Text
              content="ใบอนุญาตเลขที่ L20085SHfXkrLU"
              style={{ marginBottom: '30px' }}
              {...textStyle}
            /> */}
            <Text
              content="27 Paradise, Saint Thomas Parish Nevis Saint Kitts Nevis"
              {...textStyle}
            />
            <Text content={t('footer.phoneOne')} {...textStyle} />
            <Text content={t('footer.phoneTwo')} {...textStyle} />
            <Text content={t('footer.email')} {...textStyle} />
          </Box>
          {/* End of footer logo column */}
          <Box {...colTwo}>
            <Box className="col" {...col} key={`footer-1`}>
              <Heading content={t('footer.nav.title')} {...titleStyle} />
              <List>
                <ListItem key={`footer-1-item-1`}>
                  <a href="/" className="ListItem" title={t('footer.nav.home')}>
                    {t('footer.nav.home')}
                  </a>
                </ListItem>
                <ListItem key={`footer-1-item-2`}>
                  <a
                    href="/about"
                    className="ListItem"
                    title={t('footer.nav.about')}
                  >
                    {t('footer.nav.about')}
                  </a>
                </ListItem>
                <ListItem key={`footer-1-item-3`}>
                  <a
                    href="/howto"
                    className="ListItem"
                    title={t('footer.nav.howTo')}
                  >
                    {t('footer.nav.howTo')}
                  </a>
                </ListItem>
                <ListItem key={`footer-1-item-4`}>
                  <a
                    href="/faqs"
                    className="ListItem"
                    title={t('footer.nav.faqs')}
                  >
                    {t('footer.nav.faqs')}
                  </a>
                </ListItem>
              </List>
            </Box>
            <Box className="col" {...col} key={`footer-2`}>
              <Heading content={t('footer.account.title')} {...titleStyle} />
              <List>
                <ListItem key={`footer-2-item-1`}>
                  <a
                    href={`${config.appUrl}/login`}
                    className="ListItem"
                    target="_blank"
                    rel="noopener noreferrer"
                    title={t('footer.account.signIn')}
                  >
                    {t('footer.account.signIn')}
                  </a>
                </ListItem>
                <ListItem key={`footer-2-item-2`}>
                  <a
                    href={`${config.appUrl}/register`}
                    className="ListItem"
                    target="_blank"
                    rel="noopener noreferrer"
                    title={t('footer.account.signUp')}
                  >
                    {t('footer.account.signUp')}
                  </a>
                </ListItem>
              </List>
            </Box>
            <Box className="col" {...col} key={`footer-3`}>
              <Heading content={t('footer.terms.title')} {...titleStyle} />
              <List>
                <ListItem key={`footer-3-item-1`}>
                  <span
                    onClick={() => handleLang('th')}
                    onKeyDown={() => {}}
                    className="ListItem"
                    role="button"
                    tabIndex={-1}
                    style={{ cursor: 'pointer' }}
                  >
                    {t('footer.lang.th')}
                  </span>
                </ListItem>
                <ListItem key={`footer-3-item-2`}>
                  <span
                    onClick={() => handleLang('en')}
                    onKeyDown={() => {}}
                    className="ListItem"
                    role="button"
                    tabIndex={0}
                    style={{ cursor: 'pointer' }}
                  >
                    {t('footer.lang.en')}
                  </span>
                </ListItem>
                <ListItem key={`footer-3-item-3`}>
                  <a
                    href="/terms-and-conditions"
                    className="ListItem"
                    title={t('footer.terms.termsAndCon')}
                  >
                    {t('footer.terms.termsAndCon')}
                  </a>
                </ListItem>
              </List>
            </Box>
            <Box className="col" {...col} key={`footer-4`}>
              <Heading content={t('footer.contact.title')} {...titleStyle} />
              <a
                href={config.lineOaLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Text
                  content={`${t('footer.contact.line')} : ${config.lineOaId}`}
                  {...textStyle}
                />
              </a>
              <img
                src={LineQR}
                alt="Line OA QR Code"
                title="Line OA QR Code"
                style={{ width: '140px', marginBottom: '30px' }}
              />
            </Box>
          </Box>
          {/* End of footer List column */}
        </Box>
        <Box className="row copyRight" {...row}>
          <Text
            content={copyRightText}
            className="copyRightText"
            style={{ marginTop: '20px' }}
          />
        </Box>
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
  logoStyle: PropTypes.object
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px'
  },
  // Footer col one style
  colOne: {
    width: [1, '35%', '35%', '23%'],
    mt: [0, '13px'],
    mb: ['30px', 0],
    pl: ['15px', 0],
    pr: ['15px', '15px', 0]
  },
  // Footer col two style
  colTwo: {
    width: ['100%', '65%', '65%', '77%'],
    flexBox: true,
    flexWrap: 'wrap'
  },
  // Footer col default style
  col: {
    width: ['100%', '50%', '50%', '25%'],
    pl: '15px',
    pr: '15px',
    mb: '30px'
  },
  // widget title default style
  titleStyle: {
    color: '#fff',
    fontSize: '16px',
    fontWeight: '700',
    mb: '30px'
  },
  // Default logo size
  logoStyle: {
    width: '100px',
    mb: '15px'
  },
  // widget text default style
  textStyle: {
    color: '#8ec7ff',
    fontSize: '16px',
    mb: '10px',
    fontWeight: '300'
  }
};

export default Footer;
